import styles from "./index.module.scss";
import { useState } from "react";
import { classList } from "dynamic-class-list";

interface option {
  label?: string;
  value: string | number;
}

interface SelectFormProps {
  label?: string;
  id: string;
  required?: boolean;
  placeholder?: string;
  options: option[];
  errorText?: string;
  noMargin?: boolean;
  selectClass?: string;
  disabledOption?: string;
  value?: string | number | readonly string[] | undefined;
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  children?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  prefix?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  className?: string | string[];
}

const SelectForm = ({
  placeholder,
  id,
  label,
  options,
  disabledOption,
  errorText,
  prefix,
  noMargin = false,
  children,
  selectClass = "",
  className = [],
  ...props
}: SelectFormProps) => {
  className = typeof className === "string" ? [className] : className;

  let errorClass = null;
  let noMarginClass = null;
  let prefixClass = null;

  if (errorText && errorText?.length > 0) {
    errorClass = styles["select--error"];
  }

  if (noMargin) {
    noMarginClass = styles["form_control--no-margin"];
  }

  if (prefix) {
    prefixClass = styles["select--prefix"];
  }

  return (
    <div
      className={classList([styles.form_control, noMarginClass, ...className])}
    >
      {label && (
        <label className={classList([styles.label])} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={styles.select_holder}>
        {prefix && <div className={styles.prefix}>{prefix}</div>}

        <select
          className={classList([
            selectClass,
            styles.select,
            errorClass,
            prefixClass
          ])}
          placeholder={placeholder}
          {...props}
        >
          {disabledOption && (
            <option value={""} disabled>
              {disabledOption}
            </option>
          )}
          {options &&
            options.map((option, i) => {
              return (
                <option key={i} value={`${option.value}`}>
                  {option.label || option.value}
                </option>
              );
            })}
        </select>
      </div>
      {errorText && (
        <span className={classList([styles.error_message])}>{errorText}</span>
      )}
      {children}
    </div>
  );
};

export default SelectForm;
