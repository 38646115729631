import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { getCurrentLocationApi } from "api/location";
import Modal from "components/UI/molecules/Modal";
import { useEffect, useRef, useState, memo } from "react";
import styles from "./index.module.scss";

const MapModal = ({
  showModal,
  onClose,
  setSingleSelections
}: {
  showModal: boolean;
  onClose: () => void;
  setSingleSelections: any;
}) => {
  const [position, setPosition] = useState({ lng: 3.327709, lat: 6.457171 });

  const render = (status: Status): React.ReactElement => {
    if (status === Status.FAILURE) return <div>error..</div>;
    return <div>loading..</div>;
  };

  return (
    <Modal className={styles.modal} showModal={showModal} onClose={onClose}>
      <div className='mt-5 d-flex align-items-center flex-column justify-content-center'>
        <h1 className='mb-5'>Delivery location</h1>
        <Wrapper
          apiKey='AIzaSyDrU36CV04QpGrrGcSzvKYJ7GUWnpOqhQw'
          render={render}
        >
          <MyMapComponent
            zoom={12}
            center={{ lat: position.lat, lng: position.lng }}
            setPosition={setPosition}
            setSingleSelections={setSingleSelections}
          />
        </Wrapper>
      </div>
    </Modal>
  );
};

export default MapModal;

const MyMapComponent = memo(
  ({
    center,
    zoom,
    setPosition,
    setSingleSelections
  }: {
    center: google.maps.LatLngLiteral;
    zoom: number;
    setPosition: (position: { lng: number; lat: number }) => void;
    setSingleSelections: any;
  }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      // @ts-ignore
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom
      });
      const marker = new google.maps.Marker({
        position: center,
        map: map,
        draggable: true //make it draggable
      });

      marker.setMap(map);

      // Listen for drag events!
      google.maps.event.addListener(marker, "dragend", async () => {
        const lng = marker.getPosition()?.lng() || center.lng;
        const lat = marker.getPosition()?.lat() || center.lat;
        setPosition({ lng, lat });

        const data = await getCurrentLocationApi(lat, lng);

        setSingleSelections([
          { label: data.data.full_address, value: data.data.place_id }
        ]);
      });
    });

    return <div ref={ref} className={styles.map} id='map' />;
  }
);
