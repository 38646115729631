import styles from "./index.module.scss";
import { classList } from "dynamic-class-list";

interface CheckboxProps {
  label?: string | React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  checked?: boolean;
  onChange: () => void;
  alt?: boolean;
}

const Checkbox = ({ label, checked = false, onChange, alt }: CheckboxProps) => {
  let checkedStyle = null;
  if (checked) {
    checkedStyle = styles.checked;
  }
  return (
    <label className={`${styles.container} ${styles.alt}`}>
      {label}
      <input
        type='checkbox'
        name=''
        id=''
        checked={checked}
        onChange={onChange}
      />
      <span
        className={classList([
          styles.checkmark,
          checkedStyle,
          `${alt && styles.alt}`
        ])}
      ></span>
    </label>
  );
};

export default Checkbox;
