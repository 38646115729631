import styles from "./index.module.scss";
import { useState, useRef, useEffect } from "react";
import CloseIcon from "assets/svgs/icons/close2.svg";
import CurrentLocation from "assets/svgs/icons/current-location.svg";
import Map from "assets/svgs/icons/map.svg";
import { classList } from "dynamic-class-list";
import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Button from "components/UI/atoms/Button";
import Loading from "components/UI/atoms/Loading";
import { useClickOutsideListenerRef } from "utils/useClickOutsideListenerRef";

interface option {
  label?: string;
  value: string | number;
}

interface TypeaheadFormProps {
  label?: string;
  id: string;
  required?: boolean;
  placeholder?: string;
  options: option[];
  errorText?: string;
  noMargin?: boolean;
  selectClass?: string;
  disabledOption?: string;
  value?: string | number | readonly string[] | undefined;
  onSelected: (selected: any) => void;
  children?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  prefix?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  className?: string | string[];
  onClear?: () => void;
  isAsync?: boolean;
  onSearch?: (query: string) => void;
  loading?: boolean;
  openMapModal?: () => void;
  setSingleSelections: (option: option[]) => void;
  singleSelections: option[];
  getCurrentLocation?: () => void;
}

const TypeaheadForm = ({
  placeholder,
  id,
  label,
  options,
  disabledOption,
  errorText,
  prefix,
  noMargin = false,
  children,
  selectClass = "",
  className = [],
  onClear,
  onSelected,
  isAsync = false,
  onSearch,
  loading = false,
  openMapModal,
  setSingleSelections,
  singleSelections,
  getCurrentLocation,
  ...props
}: TypeaheadFormProps) => {
  const ref = useRef<any>();
  className = typeof className === "string" ? [className] : className;
  const [openMenu, setOpenMenu] = useState<boolean | undefined>(false);

  const ref2 = useClickOutsideListenerRef(() => {
    setOpenMenu(false);
  });

  let errorClass = null;
  let noMarginClass = null;
  let prefixClass = null;

  if (errorText && errorText?.length > 0) {
    errorClass = styles["select--error"];
  }

  if (noMargin) {
    noMarginClass = styles["form_control--no-margin"];
  }

  if (prefix) {
    prefixClass = styles["select--prefix"];
  }

  return (
    <div
      className={classList([styles.form_control, noMarginClass, ...className])}
    >
      {label && (
        <label className={classList([styles.label])} htmlFor={id}>
          {label}
        </label>
      )}
      <div ref={ref2} className={styles.select_holder}>
        {prefix && <div className={styles.prefix}>{prefix}</div>}
        {isAsync ? (
          <AsyncTypeahead
            id={id}
            ref={ref}
            isLoading={loading}
            labelKey='label'
            className={classList([
              selectClass,
              styles.select,
              errorClass,
              prefixClass
            ])}
            open={openMenu}
            onFocus={() => setOpenMenu(true)}
            onChange={(selected) => {}}
            onSearch={onSearch!}
            options={options}
            placeholder={placeholder}
            selected={singleSelections}
            renderMenu={(options) => {
              return (
                <MenuComp
                  closeMenu={() => {
                    ref.current.hideMenu();
                    setOpenMenu(false);
                  }}
                  onChangeAsync={(selected) => {
                    setSingleSelections(selected);
                    onSelected(selected[0]);
                  }}
                  getCurrentLocation={getCurrentLocation!}
                  options={options}
                  openMapModal={openMapModal!}
                />
              );
            }}
          />
        ) : (
          <Typeahead
            id={id}
            labelKey='label'
            className={classList([
              selectClass,
              styles.select,
              errorClass,
              prefixClass
            ])}
            onChange={(selected) => {
              // @ts-ignore
              setSingleSelections(selected);
              onSelected(selected[0]);
            }}
            options={options}
            placeholder={placeholder}
            selected={singleSelections}
          />
        )}
        <div className={styles.postfix}>
          {singleSelections[0] && (
            <>
              {loading ? (
                <div className={styles.loader}>
                  <Loading />
                </div>
              ) : (
                <Button
                  size='small'
                  label={<CloseIcon />}
                  color='white'
                  onClick={() => {
                    setSingleSelections([]);
                    setOpenMenu(undefined);
                    onSelected({ label: "", value: "" });
                  }}
                  className={styles["clear-button"]}
                />
              )}
            </>
          )}
        </div>
      </div>

      {errorText && (
        <span className={classList([styles.error_message])}>{errorText}</span>
      )}
      {children}
    </div>
  );
};

const MenuComp = ({
  options,
  openMapModal,
  onChangeAsync,
  getCurrentLocation,
  closeMenu
}: {
  options: any;
  openMapModal: () => void;
  onChangeAsync: (option: option[]) => void;
  getCurrentLocation: () => void;
  closeMenu: () => void;
}) => {
  return (
    <div className={styles.menu}>
      {options.map((option: any) => (
        <div
          onClick={(evt) => {
            onChangeAsync([option]);
            closeMenu();
          }}
          key={option.label}
          className={styles.menu__text}
        >
          {option.label}
        </div>
      ))}
      <div className={styles.menu__extras}>
        <div onClick={() => openMapModal()} className={styles.menu__extra}>
          <Map />
          <p>Choose on map</p>
        </div>
        <div
          onClick={() => {
            getCurrentLocation();
            closeMenu();
          }}
          className={styles.menu__extra}
        >
          <CurrentLocation />
          <p>Use your current location</p>
        </div>
      </div>
    </div>
  );
};

export default TypeaheadForm;
