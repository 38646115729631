import React, { useEffect, useState } from "react";
import { getCurrentLocationApi, searchLocation } from "api/location";
import TypeaheadForm from "components/UI/molecules/TypeaheadForm";
import MapModal from "../MapModal";
import { isClient, isValidJSON } from "utils/utils";

const AsyncTypeaheadComponent = ({
  styles,
  setLocation,
  errorText,
  setErrorText,
  label
}: {
  styles?: any;
  errorText?: string;
  setErrorText?: (error: string) => void;
  setLocation: any;
  label?: string;
}) => {
  interface Option {
    label?: string;
    value: string | number;
  }

  const address = isClient && localStorage.getItem("address");
  const [locationOptions, setLocationOptions] = useState([]);
  const [singleSelections, setSingleSelections] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [openMap, setOpenMap] = useState(false);

  const getCurrentLocation = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(async (position) => {
      const data = await getCurrentLocationApi(
        position.coords.latitude,
        position.coords.longitude
      );

      setSingleSelections([
        { label: data.data.full_address, value: data.data.place_id }
      ]);
      setLocation(data.data.full_address);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (singleSelections.length > 0)
      localStorage.setItem("address", JSON.stringify(singleSelections));
  }, [singleSelections]);

  useEffect(() => {
    if (address && isValidJSON(address)) {
      setSingleSelections(JSON.parse(address));
    }
  }, []);
  return (
    <>
      <TypeaheadForm
        placeholder="What's your address"
        disabledOption='Choose your location'
        id='location'
        options={locationOptions}
        isAsync
        label={label}
        selectClass={styles}
        setSingleSelections={setSingleSelections}
        singleSelections={singleSelections}
        loading={loading}
        onSearch={async (query) => {
          setLoading(true);
          setSingleSelections([]);
          const data = await searchLocation(query);
          const fData = data.data.map((loc: any) => ({
            label: loc.address,
            value: loc.place_id
          }));
          setLocationOptions(fData);
          setLoading(false);
        }}
        openMapModal={() => {
          setOpenMap(true);
        }}
        getCurrentLocation={getCurrentLocation}
        onSelected={(selected) => {
          if (errorText?.length) {
            setErrorText!("");
          }

          setLocation(selected?.label);
        }}
      />
      <MapModal
        setSingleSelections={setSingleSelections}
        showModal={openMap}
        onClose={() => setOpenMap(false)}
      />
    </>
  );
};

export default AsyncTypeaheadComponent;
