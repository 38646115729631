import { generateLocationToken } from "utils/utils";
import { AxiosRequestConfig } from "axios";
import api from "./axios";

export const getCurrentLocationApi = async (lat: number, long: number) => {
  const token = generateLocationToken();
  let config: AxiosRequestConfig | undefined;
  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  const { data } = await api.get(
    `location/current?lat=${lat}&long=${long}`,
    config
  );
  return data;
};

export const searchLocation = async (address: string) => {
  const token = generateLocationToken();
  let config: AxiosRequestConfig | undefined;
  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  const { data } = await api.get(`location/search?q=${address}`, config);
  return data;
};

export const confirmLocationRadius = async (
  vendor_branch_id: string,
  address: string
) => {
  const token = generateLocationToken();
  let config: AxiosRequestConfig | undefined;
  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  const { data } = await api.post(
    `customer-service-proxy/website/delivery-info`,
    {
      vendor_branch_id,
      address,
    },
    config
  );
  return data;
};
